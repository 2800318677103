<!--损耗率统计-->
<template>
    <widget-framework v-if="dataLoaded" title="损耗率统计" :show-border="false">
        <template #header>
            <div class="headerContainer">
                <span @click="$router.push('/business/customerAccount')">损耗率统计</span>
                <el-date-picker
                    v-model="monthrange"
                    type="monthrange"
                    range-separator="~"
                    start-placeholder="开始月份"
                    end-placeholder="结束月份"
                    value-format="yyyy-MM"
                    style="width: 180px"
                    :clearable="false"
                    @change="monthRangeChange"
                >
                </el-date-picker>
            </div>
        </template>
        <template #content>
            <v-chart :options="opt" :autoresize="true" style="height:100%;width:100%"></v-chart>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>

<script>
import WidgetFramework from '@/components/widgets/WidgetFramework';
import EchartBase from '@/components/widgets/EchartBase';
import {tooltip, white, lightGrey, cyan, grey, blue, red} from '../constant';
import numeral from 'numeral';
import dayjs from 'dayjs';

export default {
    name: 'AttritionRateStatistics',
    components: {
        WidgetFramework,
    },
    mixins: [EchartBase],
    data() {
        const startMonth = dayjs().subtract(11, 'month').format('YYYY-MM');
        const endMonth = dayjs().format('YYYY-MM');
        return {
            dataLoaded: false,
            opt: {
                title: {
                    text: '平均损耗率：14%',
                    textStyle: {
                        color: white,
                        fontSize: 14,
                    },
                    top: 'top',
                    left: 'center',
                },
                textStyle: {
                    color: lightGrey,
                },
                tooltip: {
                    trigger: 'axis',
                    position: this.setTooltipPosition,
                    axisPointer: {
                        type: 'cross',
                        shadowStyle: {
                            color: cyan,
                            opacity: 0.2,
                        },
                    },
                    backgroundColor: tooltip.backgroundColor,
                    borderColor: tooltip.borderColor,
                    borderWidth: tooltip.borderWidth,
                    extraCssText: tooltip.extraCssText,
                    formatter: (params) => {
                        const {
                            month,
                            purchaseQuantity,
                            purchaseQuantityYoY,
                            purchaseQuantityMoM,
                            saleQuantity,
                            saleQuantityYoY,
                            saleQuantityMoM,
                            electricLossRate,
                            electricLossRateYoY,
                            electricLossRateMoM,
                        } = params[0].data;
                        const monthStr = dayjs(month).format('YYYY年M月');
                        return `${monthStr}<br/>
                                    售电量：${this.formatterAmount(saleQuantity)}；
                                    同比：${this.formatterYoYOrMoM(saleQuantityYoY, true)}；
                                    环比：${this.formatterYoYOrMoM(saleQuantityMoM, true)}<br/>
                                    购电量：${this.formatterAmount(purchaseQuantity)}；
                                    同比：${this.formatterYoYOrMoM(purchaseQuantityYoY, true)}；
                                    环比：${this.formatterYoYOrMoM(purchaseQuantityMoM, true)}<br/>
                                    电损率：${this.formatterYoYOrMoM(electricLossRate)}；
                                    同比：${this.formatterYoYOrMoM(electricLossRateYoY, true)}；
                                    环比：${this.formatterYoYOrMoM(electricLossRateMoM, true)}<br/>`;
                    },

                },
                legend: {
                    data: ['购电量', '售电量', '损耗率'],
                    top: 'bottom',
                    textStyle: {
                        color: cyan,
                        fontSize: 14,
                    },
                },
                grid: {
                    top: '40',
                    left: '10',
                    right: '10',
                    bottom: '30',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        axisPointer: {
                            type: 'shadow',
                        },
                        axisLabel: {
                            formatter: (value) => {
                                return dayjs(value).format('YY/MM');
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                color: grey,
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位：千瓦',
                        axisLine: {
                            lineStyle: {
                                color: grey,
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    {
                        type: 'value',
                        name: '单位：百分比',
                        axisLabel: {
                            formatter: (value) => {
                                return numeral(value).format('0.0%');
                            },
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: grey,
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '购电量',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: blue,
                            },
                        },
                        encode: {
                            x: 'month',
                            y: 'purchaseQuantity',
                        },
                    },
                    {
                        name: '售电量',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: cyan,
                            },
                        },
                        encode: {
                            x: 'month',
                            y: 'saleQuantity',
                        },
                    },
                    {
                        name: '损耗率',
                        type: 'line',
                        symbol: 'circle',
                        symbolSize: 10,
                        lineStyle: {
                            type: 'dashed',
                            color: red,
                        },
                        yAxisIndex: 1,
                        encode: {
                            x: 'month',
                            y: 'electricLossRate',
                        },
                    },
                ],
                dataset: {
                    source: [],
                },
            },
            monthrange: [startMonth, endMonth],
        };
    },
    methods: {
        formatterAmount(value) {
            return (value == null || value === undefined) ? '~' : value + 'kWH';
        },
        formatterYoYOrMoM(value, showPlus = false) {
            if (showPlus) {
                return (value == null || value === undefined) ? '~' : numeral(value).format('+0.0%');
            }
            return (value == null || value === undefined) ? '~' : numeral(value).format('0.0%');
        },
        monthRangeChange([startMonth, endMonth]) {
            this.listPurchaseAndSaleOfElectricity(startMonth, endMonth);
        },
        listPurchaseAndSaleOfElectricity(startMonth, endMonth) {
            this.$client.listPurchaseAndSaleOfElectricity({startMonth, endMonth}).then((ret) => {
                const {data} = ret || {};
                let purchaseQuantityTotal = 0;
                let saleQuantityTotal = 0;
                data.forEach((item) => {
                    purchaseQuantityTotal += (item.purchaseQuantity || 0);
                    saleQuantityTotal += (item.saleQuantity || 0);
                });
                console.log('购电量', purchaseQuantityTotal);
                console.log('售电量', saleQuantityTotal);
                if (saleQuantityTotal) {
                    this.opt.title.text = `平均损耗率：${this.formatterYoYOrMoM(1.0 * (purchaseQuantityTotal - saleQuantityTotal) / purchaseQuantityTotal)}`;
                } else {
                    this.opt.title.text = `平均损耗率：~`;
                }
                this.opt.dataset.source = data || [];
                this.dataLoaded = true;
            });
        },
    },
    mounted() {
        this.listPurchaseAndSaleOfElectricity(this.monthrange[0], this.monthrange[1]);
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';

.headerContainer {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);

    /deep/ .el-input__inner {
        border: 1px solid @lightBlue;
        background-color: rgba(0, 204, 255, 0.2);
        color: @cyan;

        input {
            background-color: unset;
            color: @cyan;
        }
    }
}
</style>
